import React from "react";
import "./styles.css";
function Header() {
  return (
    <>
      <header className="masthead" id="home">
        <div className="container px-4 px-lg-5">
          <div className="col-lg-12">
            <div className="row  text-center mast-card">
              <div className="col-lg-8 align-self-end">
                <h1 className="title">CrypTotems Public Minting Is CLOSED!</h1>
              </div>
              <div className="col-lg-8 align-self-end">
                <p className="text-yellow mb-3">
                  CrypTotems is a collection of 555 NFT totem avatars. <br />
                  We love nature and we are fascinated by the blockchain. We are
                  the luddites of the metaverse. <br />
                  Expect us!
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
