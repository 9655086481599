import React from "react";

function Inspiration() {
  return (
    <>
      <section
        className="page-section web-inspiration bg-inspiration"
        id="inspiration"
      >
        <div className="container ">
          <div className="row ">
            <div className="col-lg-8 col-md-12 col-sm-12 text-center mx-auto  inspiration-card">
              <h2 className="text-white mt-0">INSPIRATION</h2>
              <hr className="divider divider-light" />
              <p className="text-white-75 mb-4 text-start">
                “I drove the Mother Road, Route 66, in a rental car in 2005, all
                the way from San Diego to New York. It was January so it was a
                tough trip at times. I got the chance to see more of the United
                States within that six days, than within all those years I was
                living there as an art student. I ate lunch at the infamous
                Bagdat Cafe and saw some interesting places. But there’s one
                place that I’ll never forget from that trip; Santa Fe is still
                vivid in my mind. Putting aside that it was Christmas time and
                this little town was already crowned with fresh snow and
                lanterns, handmade native American art I saw all around was
                mesmerizing. I love indigenous art in general and seeing their
                small imperfections, caused by crude instruments used to make
                them. Those artifacts are what make them perfect for me. But
                when looking at native American patterns and pottery designs,
                almost indistinguishable from digital vector art, one can not
                talk about “adorable, small imperfections”. Even at first
                glance, it’s crystal clear that an imperfectionist approach is
                not acceptable within this tradition in any way. Every design
                element, every pattern, every part of the craft, had been
                studied and transferred for ages to reach such heights.{" "}
              </p>
              <p className="text-white-75 mb-4 text-start">
                But I didn’t see totem poles at Santa Fe, that’s just one part
                of the story…{" "}
              </p>
              <p className="text-white-75 mb-4 text-start">
                My fascination with character art comes from childhood cartoons.
                I remember watching cartoons to relax when no one was home or
                when I was scared at night. Animalympics, I think that was it. I
                watched that cartoon over and over again and every single time
                it changed my mood for the better. Now that I think about it,
                even as a little kid, what I was trying to escape was the
                existential void that every human has to suffer.{" "}
              </p>
              <p className="text-white-75 mb-4 text-start">
                Art is our biggest weapon of distraction from that void.
                Characters and stories can sustain that distraction much longer
                than other forms of art. They mirror us; we are curious about
                ourselves the most. Admiration for the native American art and
                the character art of the 20th century American artists resulted
                in this collection. Most of the time, an avatar will show tiny
                on the screen, so the design must be clearly readable even at
                small sizes. They need to look unique and easily separated from
                other pieces in the collection as well. After all, we identify
                ourselves with them. These technical advantages and artistic
                connections made it clear to me that totems are the perfect
                avatars. Each face is unique. Each face conveys a different
                emotion. Each face tells a different story.{" "}
              </p>
              <p className="text-white-75 mb-4 text-start">
                Essential question about any character was coined by masters
                Frank Thomas and Ollie Johnston in their book, The Illusion of
                Life:{" "}
                <i>
                  “What is the character thinking, and why does he feel this
                  way?”
                </i>{" "}
              </p>
              <p className="text-white-75 mb-4 text-start">
                It was a blast working on all those expressions, intricate
                patterns and bright colors. It was even more fun to watch a
                piece of code generate thousands of unique combinations in
                seconds. NFT avatars are an important part of our digital
                identities. Here’s your chance to mint an awesome pfp, for free.{" "}
              </p>
              <p className="text-white-75 mb-4 text-start">
                Really, what is your character thinking and why do you think it
                feels this way?”{" "}
              </p>

              <p className="text-white-75 mb-5 text-start"> </p>
              <a className="btn btn-light btn-xl mt-2" href="#team">
                TEAM
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Inspiration;
