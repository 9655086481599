import React from "react";

function Team() {
  return (
    <>
      <section className="page-section bg-texture web-team" id="team">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 text-center mx-auto  team-card">
              <h2 className="text-white mt-0">Team</h2>
              <hr className="divider divider-light" />
              <p className="text-white-75 mb-4">
                {" "}
                We are a team of focused and hard working entrepreneurs, some
                with decades of industry experience in our respective fields. We
                bring art, development, investment and management to a round
                table.
              </p>

              <h2 className="text-white mt-0">Artist</h2>
              <hr className="divider divider-light" />
              <div className="card mb-3 bg-texture avatar-col">
                <div className="row g-0 avatar">
                  <div className="col-md-2 mt-3 ">
                    <img
                      src="./assets/img/jeff.jpg"
                      className="img-fluid rounded-start"
                      alt="Jeff Treves"
                      title="Jeff Treves"
                    />
                  </div>
                  <div className="col-md-10 text-start">
                    <div className="card-body">
                      <p className="text-white-75 mb-4">
                        Chief artist Jeff Treves is a co-founder of our company.
                        Past clients include Disney Channel, Scholastic –
                        Fischer Price, Mattel, Disney+. Chief's wide range of
                        experiences in feature film, video game, TV series,
                        music and education fields are the backbone of our
                        multi-dimensional approach to the art of the future.
                        With 18 years of industry experience as an award winning
                        animation director, illustrator and art educator, Jeff
                        proposes a plethora of creative ideas for the Project.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="text-white mt-0">Developers</h2>
              <hr className="divider divider-light" />
              <div className="card mb-1 bg-texture">
                <div className="row g-0 avatar">
                  <div className="col-md-2 avatar-col">
                    <img
                      src="./assets/img/fatih.jpg"
                      className="img-fluid rounded-start"
                      alt="Fatih Günalp"
                      title="Fatih Günalp"
                    />
                  </div>
                  <div className="col-md-10 text-start">
                    <div className="card-body">
                      <p className="text-white-75 mb-4">
                        Fatih Günalp - Front end develpment & project
                        architecture. Computer geek, long time programmer. Six
                        years of experience as a programming instructor at
                        various institutions. Fatih's main focus is maximizing
                        user experience in our products.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3 bg-texture">
                <div className="row g-0 avatar">
                  <div className="col-md-2 avatar-col">
                    <img
                      src="./assets/img/cagri.jpg"
                      className="img-fluid rounded-start"
                      alt="Cağrı Yolyapar"
                      title="Çağrı Yolyapar"
                    />
                  </div>
                  <div className="col-md-10 text-start">
                    <div className="card-body">
                      <p className="text-white-75 mb-4">
                        Nuri Çagrı Yolyapar- A passionate back end developer who
                        sees programming as an art form. Being a long time
                        programmer, he feels the beauty and process of
                        developing a project just like a poet feels while
                        writing his/her poems and he is a fan of the Cyberpunk
                        genre... Also he truly loves Star Wars...
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <a className="btn btn-light btn-xl" href="#home">
                HOME
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Team;
