import React from "react";
import "./styles.css";

function Roadmap() {
  return (
    <>
      <section className="page-section web-roadmap bg-texture" id="roadmap">
        <div className="container ">
          {/* <div className="row ">
            <div className="col-lg-8 col-md-12 col-sm-12 text-center mx-auto roadmap-card ">
              <h2 className="text-white mt-0">
                CrypTotems do not have a roadmap. It is the Genesis collection
                of our upcoming projects.
              </h2>
              <hr className="divider divider-light" />
              <p className="text-white-75 mb-4 text-start">
                {" "}
                We have been working on a platform that will focus mainly on
                empowering artists within a specific field, discovering hidden
                gems, reuniting with nature as a community and a better economic
                future for all. The economy of the past century created the
                precariat class. Blockchain technology challenges this scheme.
                We want to unearth, polish and re-popularize creativity and hard
                work. We believe that is where the real value lies. It is our
                dream, in essence, our roadmap at heart. Instead of writing down
                arbitrary stages of a roadmap and trying to catch up with them,
                we want to respect the ever-evolving, fast-pacing NFT space, and
                formulate the roadmap as we deliver. We will deliver first and
                then draw our path together with our community. On the other
                hand, our destination is crystal clear.
              </p>
              <p className="text-white-75 mb-4 text-start">
                CrypTotems do not have any utility on their own. But you never
                know what the future holds. Take a hint!{" "}
              </p>
              <p className="text-white-75 mb-4 text-start">
                Other than being awesome profile pictures. If you observe
                closely, they are three avatars packed into one NFT. There are
                no plans to set up a discord server for this collection. And
                since we are not promising much, minting will be free to all the
                "chosen ones". As we keep building our platform, holders of this
                Genesis collection will benefit from future perks and alpha
                status as early adopters.
              </p>
              <p className="text-white-75 mb-4 text-start">
                If the collection becomes popular in time and takes on a life of
                its own, anything is possible. It is up to the tribespeople to
                decide its fate!{" "}
              </p>
              <p className="text-white-75 mb-5 text-start"> </p>
              <a className="btn btn-light btn-xl mt-2" href="#inspiration">
                Inspiration
              </a>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default Roadmap;
