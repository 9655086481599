import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import Roadmap from "./components/Roadmap/Roadmap";
import Inspiration from "./components/Inspiration/Inspiration";
import Team from "./components/Team/Team";

function App() {
  return (
    <>
      <Navbar />
      <Header />
      <Roadmap />
      <Inspiration />
      <Team />
    </>
  );
}

export default App;
